<template>
  <!---start-wrap---->

  <!---start-content---->
  <div class="">
    <!-- {{ info }} -->
    <div class="wrap">
      <div id="main" role="main">
        <div class="tableBG">123</div>
        <div class="navbarFix">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
              <span style="font-size: 20px; font-weight: bold; margin-left: 5px"
                >商品類別</span
              >
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                  <li class="nav-item tableItem" @click="getItem()">
                    <a class="nav-link active" aria-current="page" href="#">
                      全部
                    </a>
                  </li>
                  <li
                    class="nav-item tableItem"
                    v-for="(item, index) in arr"
                    @click="getItem(item)"
                  >
                    <a class="nav-link active" aria-current="page" href="#">
                      {{ item }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div
          class="container px-3 mainData"
          style="margin-bottom: 20px; margin-top: 10px"
        >
          <div class="row g-5">
            <div
              class="col col-12 col-sm-3 col-md-6 col-lg-3"
              v-for="(item, index) in filterData"
            >
              <router-link :to="/Page/ + item.number">
                <div class="card" style="width: 18rem">
                  <!-- <div class="cardHeader">
                   
                    <div class="contentImg" v-if="item.youtube">
                      <iframe
                        :src="
                          'https://www.youtube.com/embed/' +
                          item.youtube.split('=')[1]
                        "
                        frameborder="0"
                      />
                    </div>
                    <div class="contentImg" v-else-if="item.picture">
                      <img
                        :src="
                          'https://drive.google.com/uc?export=view&id=' +
                          item.picture.split('=')[1]
                        "
                      />
                    </div>
                    <div class="contentImg" v-else>
                      <img src="../assets/img/logo_.jpg" />
                    </div>
                  </div> -->

                  <div class="card-body">
                    <div class="post-info">
                      <div class="post-basic-info">
                        <div class="titleFont">
                          <h3 style="font-size: 18px; font-weight: bold">
                            <a href="#">{{ item.title }}</a>
                          </h3>
                        </div>
                        <span>
                          <a href="#"><label> </label>{{ item.type }}</a>
                        </span>
                        <div class="content_con">
                          <p>
                            {{ item.content.slice(0, 60) + '...' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="noData">{{ noData }}</div>
          </div>
        </div>
      </div>
    </div>

    <!---//End-content---->

    <!---//End-wrap---->
  </div>
</template>
<script>
$(document).ready(function () {
  //Hide (Collapse) the toggle containers on load
})
</script>

<script>
import '../assets/css/style.css'
import '../assets/css/main.css'
import mix from './mixinData.vue'
import Bus from './Bus.js'
export default {
  mixins: [mix],
  name: 'Home',
  data() {
    return {
      //   tableArr: [],
      arr: [],
      data: [],
      filterData: [],
      device: '',
      searchInput: '',
      noData: '',
    }
  },
  components: {},
  watch: {
    tableArr() {
      this.arr = this.tableArr
      //   console.log('arr', this.arr)
    },
    info() {
      this.data = this.info
      this.filterData = this.info
      //   console.log('data', this.data)
    },
  },
  mounted() {
    var sUserAgent = navigator.userAgent.toLowerCase()
    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp'
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
    var bIsAndroid = sUserAgent.match(/android/i) == 'android'
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
    // document.writeln("您的瀏覽裝置為：");
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      //   console.log('phone')
      this.device = 'phone'
    } else {
      //   console.log('pc')
      this.device = 'pc'
    }
    Bus.$on('input', (data) => {
      //   console.log('content input', data)
      this.searchInput = data
      this.getSearch()
    })
    // this.isLoading = true
  },
  methods: {
    getItem(itemType) {
      this.noData = ''
      //   console.log('hhii')
      this.filterData = this.data.filter((item) => {
        if (itemType) {
          return item.type == itemType
        } else {
          return item.type
        }
      })
      if (this.device == 'phone') {
        $('.navbar-toggler').click()
      }
    },
    getSearch() {
      //   this.noData = ''
      //   console.log('hi', this.noData)
      this.filterData = this.data.filter((item) => {
        if (this.searchInput) {
          var data = item.content
          //   var matchData = data.match(this.searchInput)

          return data.match(this.searchInput)
        } else {
          return item
        }
      })
    },
  },
}
</script>
